<template>
  <vx-card class="py-2">
    <h1 class="text-center">
      {{ funnel.surveyQuestionText }}
    </h1>

    <div class="flex justify-center mt-8 space-x-6">
      <vs-button
        class="px-7"
        :color="colors.rjRed"
        :text-color="colors.rjRed"
        type="border"
        @click="no"
      >
        <div class="flex items-center text-lg">
          <div class="px-1">
            <feather-icon
              class="block"
              icon="ThumbsDownIcon"
              svg-classes="text-rjRed"
            />
          </div>
        </div>
      </vs-button>
      <vs-button
        class="px-7"
        :color="colors.rjGreen"
        @click="yes"
      >
        <div class="flex items-center text-lg">
          <div class="px-1">
            <feather-icon
              class="block"
              icon="ThumbsUpIcon"
              svg-classes="text-white"
            />
          </div>
        </div>
      </vs-button>
    </div>
  </vx-card>
</template>

<script>
import { http } from '@/ky';
import { colors } from '@/../themeConfig';

export default {
  name: 'RjSurveyQuestion',

  props: {
    survey: {
      type: Object,
      required: true,
    },

    funnel: {
      type: Object,
      required: true,
    },
  },

  computed: {
    colors() {
      return colors;
    },
  },

  methods: {
    async yes() {
      const payload = {
        id: this.survey.id,
        sentiment: 1,
        status: 'Completed',
      };

      await http.patch(`surveys/${this.survey.id}`, { json: payload });
      this.$emit('answer', true);
    },

    async no() {
      const payload = {
        id: this.survey.id,
        sentiment: 0,
        status: 'Completed',
      };

      await http.patch(`surveys/${this.survey.id}`, { json: payload });
      this.$emit('answer', false);
    },
  },
};
</script>
